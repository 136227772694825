<template>

<div class="loadFail table-hook fadeInRight">
    <el-table :data="tableData">
        <el-table-column label="导入表编号" prop="importCardId" width="300px"></el-table-column>
        <el-table-column label="电话" prop="phone" show-overflow-tooltip></el-table-column>
        <el-table-column label="ICCID号" prop="iccidMark" width="180px"></el-table-column>
        <el-table-column label="描述" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" prop="opt">
            <template slot-scope="scope">
                <a href="javascript:void(0)" @click="viewRecord(scope.row.id)">详情</a>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 50,100,1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="total">
        </el-pagination>
    </div> 

    <el-dialog title="导卡失败详情" :visible.sync="dialogVisible">
        <div>
            <p class="dia-title">导入表编号:</p>
            <el-input v-model="importCardId" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">电话:</p>
            <el-input v-model="phone" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">ICCID号:</p>
            <el-input v-model="iccidMark" readonly></el-input>
        </div>

        <div>
            <p class="dia-title">描述:</p>
            <el-input v-model="remark" readonly></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="dialogVisible = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">

import {importListFail,findById} from '@/api/cardManagement/cardImport.js'
import {messageBox,confirmBox} from '@/utils/common.js'

export default {
    data(){
        return {
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogVisible:false,
            importCardId:'',
            phone:'',
            iccidMark:'',
            remark:''
        }
    },
    mounted(){
        this._importListFail()
    },
    methods:{
        viewRecord(id){
            this.dialogVisible = true
            findById({id}).then((res)=>{
              this.importCardId = res.entity.importCardId
              this.phone = res.entity.phone
              this.iccidMark = res.entity.iccidMark
              this.remark = res.entity.remark
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._importListFail()  
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._importListFail()
        },
        _importListFail(){
            let params = this.$route.query
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            importListFail(params).then((res) =>{
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
<style>
     .loadFail .dia-title{margin-top:10px;margin-bottom:6px;}
</style>
